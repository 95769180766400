import { SectionInfo } from "odo";
import { FC, useCallback, useMemo, useState } from "react";
import { useOutlineActions } from "./hooks/useOutlineActions";
import useOutlineData from "./hooks/useOutlineData";
import useOutlineLayout, {
  OutlineElementLayoutManager,
} from "./hooks/useOutlineLayout";
import OutlineDragDropProvider, { useOutlineDragDrop } from "./OutlineDragDrop";
import { Flipped, Flipper } from "react-flip-toolkit";
import { HierarchicalSectionInfo } from "./hooks/types";
import OutlineElementView from "./OutlineElementView";
import Button from "components/common/Button";
import Columns from "components/common/containers/Columns";
import { useOdoEditorRef } from "hooks/odo-editor/useOdoEditorRef";
import { cn } from "lib/utils";

interface OutlineViewProps {
  editable?: boolean;
  className?: string;
}

/**
 * Panel that displays the document outline and provides functionality
 * for manipulating outline elements
 */
const OutlineView: FC<OutlineViewProps> = ({ className, editable = false }) => {
  const layoutManager = useOutlineLayout();

  // Enhanced outline data with shadow layer functionality
  const { hierarchicalSections, actions } = useOutlineData(layoutManager);
  return (
    <OutlineDragDropProvider layoutManager={layoutManager} {...actions}>
      <div
        className={cn(
          "hover:overflow-y-auto pr-[var(--space-scroll-bar)] hover:pr-none overflow-hidden w-full",
          className
        )}
      >
        <OutlineElementListView
          layoutManager={layoutManager}
          hierarchicalSections={hierarchicalSections}
          editable={editable}
        />
      </div>
    </OutlineDragDropProvider>
  );
};

const OutlineElementListView: FC<{
  layoutManager: OutlineElementLayoutManager;
  hierarchicalSections: HierarchicalSectionInfo[];
  editable?: boolean;
}> = ({ layoutManager, hierarchicalSections, editable = false }) => {
  const editor = useOdoEditorRef();
  const [collapsedSections, setCollapsedSections] = useState<
    Record<string, boolean>
  >({});
  const [selectedSectionId, setSelectedSectionId] = useState<string | null>(
    null
  );
  const { navigateToSection } = useOutlineActions();
  const { draggingId } = useOutlineDragDrop();

  const handleSectionClick = useCallback(
    (section: SectionInfo) => {
      setSelectedSectionId(section.id);
      navigateToSection(section);
    },
    [navigateToSection]
  );

  const handleNameChange = useCallback(
    (section: SectionInfo, newName: string) => {
      editor.renameSection(section.id, newName);
    },
    [editor]
  );

  const handleKeyDown = useCallback(
    (section: SectionInfo, event: React.KeyboardEvent) => {
      switch (event.key) {
        case "Tab":
          event.preventDefault();
          event.stopPropagation();
          if (event.shiftKey) {
            editor.dedentSection(section.id);
          } else {
            editor.indentSection(section.id);
          }
          break;
        case "Backspace":
          if (section.name.trim() === "") {
            event.preventDefault();
            event.stopPropagation();
            editor.deleteSection(section.id);
          }
          break;
      }
    },
    [editor]
  );

  const flipKey = useMemo(() => {
    // Filter sections to only include id, level, and children
    const filterSection = (
      section: HierarchicalSectionInfo
    ): Partial<HierarchicalSectionInfo> => {
      let filtered: Partial<HierarchicalSectionInfo> = {
        id: section.id,
        level: section.level,
      };
      if (section.children) {
        filtered.children = section.children.map(filterSection as any);
      }
      return filtered;
    };

    const filteredSections = hierarchicalSections.map(filterSection);
    return (
      JSON.stringify(filteredSections) +
      JSON.stringify(collapsedSections) +
      draggingId
    );
  }, [hierarchicalSections, collapsedSections, draggingId]);

  const flattenedSections = useMemo(() => {
    let flattened: HierarchicalSectionInfo[] = [];
    const flatten = (sections: HierarchicalSectionInfo[]) => {
      sections.forEach((section) => {
        flattened.push(section);
        if (
          !collapsedSections[section.id] &&
          section.id !== draggingId &&
          section.children
        ) {
          flatten(section.children);
        }
      });
    };
    flatten(hierarchicalSections);
    return flattened;
  }, [hierarchicalSections, collapsedSections, draggingId]);

  return (
    <Flipper flipKey={flipKey} spring="stiff">
      {flattenedSections.map((section) => (
        <OutlineElementView
          key={section.id}
          layoutManager={layoutManager}
          section={section}
          selectedSectionId={selectedSectionId}
          onSectionClick={handleSectionClick}
          onNameChange={handleNameChange}
          onKeyDown={handleKeyDown}
          onToggleExpand={
            section.children.length > 0
              ? () =>
                  setCollapsedSections((prev) => ({
                    ...prev,
                    [section.id]: !prev[section.id],
                  }))
              : undefined
          }
          isCollapsed={collapsedSections[section.id]}
          isDragging={draggingId === section.id}
          editable={editable}
        />
      ))}
      {editable && (
        <Flipped flipId="add-section">
          <Columns className="mx-sm my-xs">
            <Button
              icon="plus"
              text="Add section"
              onClick={() => {
                const id = editor.appendSection();
                layoutManager.focusElement(id);
              }}
            />
          </Columns>
        </Flipped>
      )}
    </Flipper>
  );
};

export default OutlineView;
